.slick-dots {
  bottom: 0px;
  height: 10%;
  /* min-height: 5vh; */
}

.slick-dots li.slick-active button::before {
  /* opacity: .75; */
  color: white;
}

.slick-dots li button::before {
  /* opacity: .25; */
  color: white;
}

.slick-slider, .slick-track{
  height: 100%;
}
.slick-list {
  height: 90%;
  color: white;
}

.slick-prev {
  left:0px;
}
.slick-next {
  right:0px;
}

.slick-initialized .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width:992px) {

  .laptop > div { 
    min-height: 0;

  }
  .slick-list {
    min-height: 30rem;
  } 

}