.popup-btn {
    background-color:white;
    color: #0096c7;
    opacity:0.8;
    border-radius: 1000vh;
    width: 10rem;
    border: 2px solid #0096c7;
    color: #0096c7;
    margin: 1rem 0rem; 
    padding: 0.4rem 0;
    font-weight: 400;
    cursor: pointer;
}

.popup-btn:hover {
    background: #0096c7;
    color: white;   
}

